import React, { FC } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { ImageResizeMode } from '@wix/search-settings-client';
import SelectFillIcon from 'wix-ui-icons-common/on-stage/SelectFill';

import { IForumListItemProps } from '../ForumListItem';
import StatsBar from './StatsBar';
import { classes, st } from './ForumListPostItem.st.css';
import { useFormatters } from '../../../../components/SearchResults/Widget/hooks';
import { stripMarkTags } from '../../../../components/SearchResults/Widget/components/stripMarkTags';
import { Thumbnail } from '../../../../components/SearchResults/Widget/components/Thumbnail';
import { xss } from '../../../../components/SearchResults/Widget/lib/xss';

const MOBILE_IMAGE_HEIGHT = 150;
const MOBILE_IMAGE_WIDTH = 280;

const DESKTOP_IMAGE_HEIGHT = 250;
const DESKTOP_IMAGE_WIDTH = 700;

const ForumListPostItem: FC<IForumListItemProps> = ({ item, onLinkClick }) => {
  const { t } = useTranslation();
  const { isMobile, isEditorX, isCssPerBreakpoint } = useEnvironment();
  const { formatDate } = useFormatters();

  const styleStates = {
    mobileView: isMobile,
    fluid: isEditorX|| isCssPerBreakpoint,
  };

  const hasMarkedComments =
    item.markedComments && item.markedComments.length > 0;

  return (
    <a
      href={item.url}
      className={st(classes.root, styleStates)}
      data-hook="item-title"
      title={stripMarkTags(item.title)}
      onClick={(e) => onLinkClick?.(e, 'title')}
    >
      <div className={classes.itemContainer}>
        {/* @TODO add member data, when available */}
        <div className={classes.titleContainer}>
          {hasMarkedComments && (
            <SelectFillIcon className={classes.titleMark} size={24} />
          )}
          <div
            className={classes.title}
            dangerouslySetInnerHTML={{ __html: xss(item.title) }}
          />
        </div>
        <div className={classes.subTitle}>
          <span>
            {t('searchResults.tabs.label.forums.inCategory', {
              category: item.categoryTitle,
            })}
          </span>
          {item.createdDate && (
            <>
              <span className={classes.dateSeparator}>·</span>
              <span>{formatDate(item.createdDate)}</span>
            </>
          )}
        </div>
        <div
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: xss(item.description) }}
        />

        {item.image && (
          <div className={classes.imageContainer}>
            <Thumbnail
              image={item.image}
              height={isMobile ? MOBILE_IMAGE_HEIGHT : DESKTOP_IMAGE_HEIGHT}
              width={isMobile ? MOBILE_IMAGE_WIDTH : DESKTOP_IMAGE_WIDTH}
              maxResponsiveImageHeight={MOBILE_IMAGE_WIDTH}
              maxResponsiveImageWidth={DESKTOP_IMAGE_WIDTH}
              resizeMode={ImageResizeMode.Crop}
              title={item.title}
            />
          </div>
        )}

        <StatsBar
          containerClassName={classes.statsContainer}
          likes={item.likeCount}
          comments={item.totalComments}
          views={item.viewCount}
        />
      </div>
    </a>
  );
};

export default ForumListPostItem;
