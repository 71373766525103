import React, { FC } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { ImageResizeMode } from '@wix/search-settings-client';
import { ForumCommentInteraction } from '@wix/client-search-sdk';
import { Badge, BadgePriority } from 'wix-ui-tpa/cssVars';

import { IForumListItemProps } from '../ForumListItem';
import StatsBar from './StatsBar';
import { classes, st } from './ForumListCommentItem.st.css';
import { useFormatters } from '../../../../components/SearchResults/Widget/hooks';
import { stripMarkTags } from '../../../../components/SearchResults/Widget/components/stripMarkTags';
import { Thumbnail } from '../../../../components/SearchResults/Widget/components/Thumbnail';
import { xss } from '../../../../components/SearchResults/Widget/lib/xss';

const MOBILE_IMAGE_HEIGHT = 150;
const MOBILE_IMAGE_WIDTH = 243;

const DESKTOP_IMAGE_HEIGHT = 250;
const DESKTOP_IMAGE_WIDTH = 663;

const ForumListCommentItem: FC<IForumListItemProps> = ({
  item,
  onLinkClick,
}) => {
  const { t } = useTranslation();
  const { isMobile, isEditorX, isCssPerBreakpoint } = useEnvironment();
  const { formatDate } = useFormatters();

  const styleStates = {
    mobileView: isMobile,
    fluid: isEditorX || isCssPerBreakpoint,
  };

  const isReply = !!item.parentId;
  const isVoteType = item.commentInteraction === ForumCommentInteraction.Vote;
  const isReactionType =
    item.commentInteraction === ForumCommentInteraction.Reaction;

  return (
    <a
      href={item.url}
      className={st(classes.root, styleStates)}
      data-hook="item-title"
      title={stripMarkTags(item.title)}
      onClick={(e) => onLinkClick?.(e, 'title')}
    >
      <div className={classes.itemContainer}>
        <div
          className={classes.title}
          dangerouslySetInnerHTML={{ __html: xss(item.title) }}
        />
        <div className={classes.subTitle}>
          {t('searchResults.tabs.label.forums.inCategory', {
            category: item.categoryTitle,
          })}
        </div>

        <div className={classes.descriptionContainer}>
          {/* @TODO add member data, when available */}
          {item.createdDate && (
            <div className={classes.subTitle}>
              {formatDate(item.createdDate)}
            </div>
          )}
          {item.marked && (
            <div className={classes.badgeContainer}>
              <Badge priority={BadgePriority.primary}>
                {t('searchResults.list.forums.bestAnswer')}
              </Badge>
            </div>
          )}
          <div
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: xss(item.description) }}
          />

          {item.image && (
            <div className={classes.imageContainer}>
              <Thumbnail
                image={item.image}
                height={isMobile ? MOBILE_IMAGE_HEIGHT : DESKTOP_IMAGE_HEIGHT}
                width={isMobile ? MOBILE_IMAGE_WIDTH : DESKTOP_IMAGE_WIDTH}
                maxResponsiveImageHeight={DESKTOP_IMAGE_HEIGHT}
                maxResponsiveImageWidth={DESKTOP_IMAGE_WIDTH}
                resizeMode={ImageResizeMode.Crop}
                title={item.title}
              />
            </div>
          )}

          <StatsBar
            containerClassName={classes.statsContainer}
            score={isVoteType && !isReply ? item.score : undefined}
            likes={isReactionType || isReply ? item.likeCount : undefined}
            replies={!isReply ? item.totalComments : undefined}
          />
        </div>
      </div>
    </a>
  );
};

export default ForumListCommentItem;
